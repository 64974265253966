<script lang="ts" setup>
withDefaults(
  defineProps<{
    title: string;
    expanded: boolean;
    empty?: boolean;
    contentClass?: string;
    contentTag?: 'p' | 'div';
  }>(),
  { contentTag: 'p' },
);
const emit = defineEmits<{
  (event: 'update:expanded', value: boolean): void;
}>();
defineSlots<{
  default: () => VNode[];
}>();
</script>

<template>
  <div class="baseExpandedBlock">
    <button
      class="baseExpandedBlock__button"
      @click="emit('update:expanded', !expanded)"
      :class="{ baseExpandedBlock__button_empty: empty }"
    >
      {{ title }}
      <SvgoSimpleChevron
        v-if="!empty"
        class="baseExpandedBlock__chevron"
        :class="{ baseExpandedBlock__chevron_collapsed: !expanded }"
      />
    </button>
    <BaseCollapse v-if="!empty" :visible="expanded" :fixed-duration="300">
      <component
        :is="contentTag"
        class="baseExpandedBlock__content"
        :class="contentClass"
      >
        <slot />
      </component>
    </BaseCollapse>
  </div>
</template>

<style lang="scss">
.baseExpandedBlock {
  &__button {
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    gap: 6px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #121212;
    padding: 0;
    background: none;
    border: 0;
    cursor: pointer;
    position: relative;
    text-align: start;

    &_empty {
      cursor: initial;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 200%;
      z-index: 1;
    }
  }

  &__chevron {
    display: block;
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;

    &_collapsed {
      transform: rotate(-180deg);
    }
  }

  &__content {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.2px;
    color: #121212;
    opacity: 0.4;
    padding-top: 10px;
    margin: 0;

    @include mq('sm') {
      line-height: 20px;
    }
  }
}
</style>
